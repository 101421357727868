<script lang="ts" setup>
defineProps<{
	small?: boolean;
}>();

const { t } = useT();
const isGuest = useIsGuest();
const { data: appInit } = useAppInitData();

const { BALLS, counterBalls, collectedBalls } = useBingoData({
	immediate: !isGuest.value && appInit.value?.bingo?.isActive
});

const ballsArray = computed(() =>
	Array.from({ length: BALLS }, (_, index) => (index < collectedBalls.value ? getRandom({ min: 1, max: 90 }) : null))
);

const shuffle = (array: { light: string; dark: string }[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const shuffledColors = computed(() => shuffle(BINGO_COLORS));
</script>

<template>
	<div :class="['progress-wrapper', { small: small }]">
		<div class="progress">
			<template v-for="(ball, index) in ballsArray">
				<MBingoBall
					v-if="ball"
					:id="index"
					:key="`ball-${index}`"
					:light="shuffledColors[index].light"
					:dark="shuffledColors[index].dark"
					:number="ball"
				/>
				<NuxtIcon v-else :key="`hole-${index}`" class="hole" name="24/bingo-empty-ball" filled />
			</template>
		</div>
		<AText class="text" :size="12" :modifiers="['bold', 'right', 'wide']" as="div">
			<span class="text-tertiary-90">{{ counterBalls }}</span> {{ t("Balls Collected") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.progress-wrapper {
	width: 100%;
}
.progress {
	margin-bottom: 8px;
	border-radius: 100px;
	background: rgba(var(--neutral-5-rgb), 0.64);
	padding: 6px 8px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 2px;
	& > * {
		position: relative;
		width: 22px;
		max-width: 22px;
		height: 22px;
		max-height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		:deep(svg) {
			margin: 0;
			width: 100%;
			height: 100%;
		}
	}
	.hole {
		opacity: 0.5;
	}
}
.text-tertiary-90 {
	text-shadow:
		0 10px 15px rgba(2, 6, 23, 0.1),
		0 4px 6px rgba(2, 6, 23, 0.05);
}
$margin: 20px;
.small.progress-wrapper {
	width: calc(100% + $margin);
	margin-left: -$margin;
	.progress {
		margin-bottom: 4px;
		padding: 2px;
		& > * {
			width: 13px;
			min-width: 13px;
			height: 13px;
		}
		:deep(.number) {
			font-size: 6px;
			font-weight: 500;
			margin: -1px auto auto -1px;
		}
	}
	.toledo {
		font-size: 10px;
	}
}
</style>
